export interface VehicleDetails {
  loc: "uk" | "ie";
  missingVariables: any;
  options: any;
  id: number;
  euClassification: string;
  firstregisteredInJapan: string | null;
  make: string;
  model: string | null;
  transmissionType: string | null;
  numberOfDoors: string | null;
  engineType: number;
  bodyType: string | null;
  engineCapacity: number;
  mileage: number;
  yearOfManufacture: number;
  yearOfRegistration: number;
  registrationNumber: string;
  registrationNumber_ie: string;
  monthOfRegistration: number;
  nox: number;
  taxStatus: string;
  taxDueDate: string;
  motStatus: string;
  motExpiryDate: string;
  dateOfLastV5CIssued: string;
  markedForExport: number;
  statCode: number;
  autotrader_id: number | null;
  wltpco2: number | null;
  priceGBP: number;
  model_tfl: string;
}

export interface VrtDetails {
  maxNox?: boolean;
  source: 'ros' | 'tcs' | 'tcs-prestige' | 'tcs-estimated-omsp';
  statCode: number;
  wltpco2: number;
  nox: number;
  euClassification: string;
  transmissionType: number;
  engineType: number;
  engineCapacity: number;
  bodyType: number;
  numberOfDoors: number;
  mileage: number;
  firstRegisteredInJapan: boolean;
  make: string;
  model: string;
  version: string;
  unmodifiedVRTAmount: number;
  vrtEuro: number;
  omsp: number;
  monthlyAdjustment: number;
  deprCode: string;
  mileageRed: number;
  deprRate: number;
  omsp_current: number;
  co2Charges: number;
  noxCharge: number;
  missingVariables?: Array<keyof VehicleDetails>; // Missing variables to collect
  similar?: any[]; // Similar matches, but not exact
  options?: string[];
  yearOfRegistration?: number;
  monthOfRegistration?: number;
}

export interface CustomsDetails {
  cost: number;
  rate: number;
  comment: string;
}

export interface VatDetails {
  vat: number;
}

export interface VehicleData {
  vehicleDetails?: any;
  vrtDetails?: VrtDetails;
  vatDetails?: VatDetails;
  customsDetails?: CustomsDetails;
  transportDetails?: TransportDetails;
}

export interface TransportDetails {
  cost: number;
  distance: number;
  origin: string;
  destination: string;
  [key: string]: any;
}



export const quoteTableSchema: { [key: string]: string } = {
  priceEur: "Purchase Price",
  vrtEuro: "VRT",
  vat: "VAT",
  customsDetails: "Customs Duty",
  transportDetails: "Transport",
  serviceFee: "Service Fee (2%)",
  total: "Total",
};
