<div class="total-table">
  <div class="table single">
    <div class="table-segment">
      @for (key of Object.keys(totalBreakdown); track key) {
        @if(totalBreakdown?.[key] !== undefined) {
          <div class="row">
            <div class="property">{{ totalSchema[key] }}</div>
            <div class="value">
              {{ totalBreakdown?.[key] | currency: "EUR":"symbol":"1.0-0" : "en" }}
            </div>
          </div>
        }
      }
    </div>
  </div>
</div>
