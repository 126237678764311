import {Component, OnInit} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {MatDialog, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {
  BusinessDetailsDialogComponent
} from "../../../components/dialogs/business-details-dialog/business-details-dialog.component";
import {DatePipe, NgIf} from "@angular/common";
import {InvoicesDialogComponent} from "../../../components/dialogs/invoices-dialog/invoices-dialog.component";
import {
  UpdatePaymentDialogComponent
} from "../../../components/dialogs/update-payment-dialog/update-payment-dialog.component";
import {ApiService} from "../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditEmailComponent} from "../../../components/dialogs/edit-email/edit-email.component";
import {EditPwdComponent} from "../../../components/dialogs/edit-pwd/edit-pwd.component";

@Component({
  selector: "app-account",
  standalone: true,
  imports: [MatButton, MatDialogContent, MatDialogTitle, RouterLink, DatePipe, NgIf],
  templateUrl: "./account.component.html",
  styleUrl: "./account.component.scss",
})
export class AccountComponent implements OnInit {
  plan = {
    description: this.userService.plan(), nextCharge: this.userService.nextBill(),
  };
  protected business_details: any;

  constructor(public dialog: MatDialog, public userService: UserService, private route: ActivatedRoute, private apiService: ApiService, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.userService.refresh()
      .catch(e => console.error(e));
    this.userService.getBusinessDetails()
      .then(businessDetails => {
        this.business_details = businessDetails;
      })
    this.route.queryParams.subscribe(async (params) => {
      if (params['session_id']) {
        // We need to validate a new payment method
        const res = await this.apiService.attachNewPayment(params['session_id']);
        if (res?.success) {
          const pmData = res.data;
          this.snackBar.open(`Added your new payment card ending in ${pmData.last4}`, undefined, {
            panelClass: 'snack-ok', duration: 10000, verticalPosition: "top"
          });
        }
      }
    })
  }

  editDetails() {
    this.dialog.open(BusinessDetailsDialogComponent);
  }

  editEmail() {
    this.dialog.open(EditEmailComponent);
  }

  editPwd() {
    this.dialog.open(EditPwdComponent);
  }

  showInvoices() {
    this.dialog.open(InvoicesDialogComponent);
  }

  showPaymentUpdate() {
    this.dialog.open(UpdatePaymentDialogComponent)
  }
}
