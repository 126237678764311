import { Component} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {UserService} from "./services/user.service";
import { NgIf} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {FooterComponent} from "./components/footer/footer.component";
import {HeaderComponent} from "./components/header/header.component";
import {FcfService} from "./services/fcf.service";
import {TitleService} from "./services/title.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoginComponent, NgIf, MatProgressSpinner, FooterComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  protected loaded = false;
  constructor(protected userService: UserService, private fcfS: FcfService, titleService: TitleService) {
    this.userService.loginEvent.subscribe(() => {
      this.loaded = true;
      // this.fcfS.init().catch(e => console.error(e));
    });
  }
}
