import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Pipe, PipeTransform } from "@angular/core";
import {
  CurrencyPipe, DatePipe,
  DecimalPipe,
  KeyValue,
  KeyValuePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet, PercentPipe,
  ViewportScroller,
} from "@angular/common";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MatOption, MatSelectChange, MatSelectModule } from "@angular/material/select";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { formatRes } from "../../../pipes/format-res.pipe";
import { formatKey } from "../../../pipes/format-key.pipe";
import { ApiService } from "../../../services/api.service";
import { CurrencyService } from "../../../services/currency.service";
import { VrmSearchComponent } from "../../../components/vrm-search/vrm-search.component";
import { StatbitsComponent } from "../../../components/statbits/statbits.component";
import {
  VrtDetails,
  VehicleDetails,
  VehicleData,
} from "./ccq.model.consts";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { QuoteComponent } from "./quote.component";
import { DialogService } from "../../../services/dialog.service";
import {FflComponent} from "../../products/ffl/ffl.component";
import {HeroBgService} from "../../../services/hero-bg.service";
import {FflContentComponent} from "../../../components/ffl-content/ffl-content.component";
import {UserActivityComponent} from "../../../components/user-activity/user-activity.component";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatSortHeader} from "@angular/material/sort";
import {TransmissionTypePipe} from "../../../pipes/transmission-type.pipe";
import {EngineTypePipe} from "../../../pipes/engine-type.pipe";
import {BodyTypePipe} from "../../../pipes/body-type.pipe";
import {DisclaimerComponent} from "../../../components/disclaimer/disclaimer.component";
import {ProductsComponent} from "../../../components/products/products.component";
import {BugService} from "../../../services/bug.service";
import {VehicleDataComponent} from "../../../components/tables/vehicle-data/vehicle-data.component";
import {VrtDataComponent} from "../../../components/tables/vrt-data/vrt-data.component";
import {CcqTotalsComponent} from "../../../components/tables/ccq-totals/ccq-totals.component";
import {VinRevealService} from "../../../services/vin-reveal.service";
import {ManualVrtComponent} from "../../../components/dialogs/manual-vrt/manual-vrt.component";
import {FeedbackService} from "../../../services/feedback.service";

@Pipe({
  standalone: true,
  name: "errorsToStrings",
})
export class errorsToStrings implements PipeTransform {
  transform(value: ValidationErrors | null): string[] {
    // console.log(value);
    if (!value) {
      return [];
    }
    return Object.values(value);
  }
}

interface StatCode {
  statCode: number;
  version?: string | null;
  make?: string | null;
  model?: string | null;
  transmissionType?: number | null;
  numberOfDoors?: number | null;
  engineType?: number | null;
  bodyType?: number | null;
  engineCapacity?: number | null;
  euClassification?: "M1" | "N1" | "L3" | null;
  firstregisteredInJapan: number;
  wltpco2?: number | null;
}

@Component({
  selector: "app-search",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    errorsToStrings,
    NgForOf,
    NgClass,
    KeyValuePipe,
    NgIf,
    formatRes,
    MatButtonModule,
    MatProgressSpinner,
    DecimalPipe,
    formatKey,
    VrmSearchComponent,
    NgOptimizedImage,
    RouterLink,
    StatbitsComponent,
    MatLabel,
    MatInput,
    MatButton,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    MatExpansionPanelDescription,
    NgOptimizedImage,
    MatTooltipModule,
    MatAccordion,
    KeyValuePipe,
    NgTemplateOutlet,
    MatIcon,
    CurrencyPipe,
    FflComponent,
    FflContentComponent,
    UserActivityComponent,
    MatTable,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatSortHeader,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    TransmissionTypePipe,
    EngineTypePipe,
    BodyTypePipe,
    DatePipe,
    PercentPipe,
    DisclaimerComponent,
    ProductsComponent,
    VehicleDataComponent,
    VrtDataComponent,
    CcqTotalsComponent,
  ],
  templateUrl: "./ccq-home.component.html",
  styleUrl: "./ccq-home.component.scss",
})
export class CcqHomeComponent implements OnInit {
  public vrtError: string | undefined;
  public vatError: string | undefined;
  public customsError: string | undefined;
  public transportError: string | undefined;
  @ViewChild("submit") submit: ElementRef | undefined;
  @ViewChild("vrmSearch") vrmSearch: VrmSearchComponent | undefined;
  detailsLoading = true;
  vrtLoading = true;
  vatLoading = false;
  customsLoading = false;
  postCodeLoading = false;
  totalsLoading = true;
  public postCode: string | undefined;
  public missingVariables: Array<keyof VehicleDetails> = [];
  public missingOptions: any = {};
  vehicleData: VehicleData = {
    vehicleDetails: undefined,
    vrtDetails: undefined,
    vatDetails: undefined,
    customsDetails: undefined,
  };
  protected readonly Number = Number;
  protected searching = false;
  protected motivationalText: string | undefined;
  protected missingVarsForm: FormGroup;
  protected missingPriceForm: FormGroup;
  protected transportFormGroup: FormGroup;
  protected similar = new MatTableDataSource<StatCode[]>([]);
  @ViewChild("reportContainer", {static: false}) reportContainer: ElementRef | undefined;
  private manuallySelectedStatCode: number | null = null;
  protected totalBreakdown?: {
    priceEur: number;
    transportDetails: number | undefined;
    vat: number | undefined;
    customsDetails: number | undefined;
    vrtEuro: number | undefined;
    total: number | undefined;
  };
  constructor(
    public scroller: ViewportScroller,
    public dialog: MatDialog,
    private apiService: ApiService,
    private dialogService: DialogService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    protected currency: CurrencyService,
    private route: ActivatedRoute,
    private hero: HeroBgService,
    protected bug: BugService,
    private vinRevealService: VinRevealService,
    private feedback: FeedbackService
  ) {
    // this.searchControl = new FormControl({value: '', disabled: this.searching});
    this.postCode = this.getpostCode();
    this.transportFormGroup = new FormGroup({
      uk: new FormControl("", { validators: [Validators.required, this.validateUKPostCode()] }),
      ie: new FormControl(this.postCode, {
        validators: [Validators.required, this.validateIrishPostCode()],
      }),
    });
    this.missingVarsForm = new FormGroup([]);
    this.missingPriceForm = new FormGroup({
      priceGBP: new FormControl("", [Validators.required]),
    });
  }

  public get total(): number | false {
    const { vrtDetails, vatDetails, customsDetails, transportDetails } = this.vehicleData;
    // console.log('this.vehicleData', this.vehicleData);
    if (vrtDetails?.vrtEuro !== undefined && vatDetails?.vat !== undefined && customsDetails?.cost !== undefined && this.priceEur !== false) {
      this.totalBreakdown = {
        priceEur: this.priceEur,
        vrtEuro: vrtDetails?.vrtEuro,
        vat: vatDetails?.vat,
        customsDetails: customsDetails?.cost,
        transportDetails: transportDetails?.cost,
        total: undefined
      };
      let total = Number(vrtDetails.vrtEuro) + Number(vatDetails.vat) + Number(customsDetails.cost) + this.priceEur;
      if (this.vehicleData.transportDetails?.cost) {
        total += Number(this.vehicleData.transportDetails?.cost);
      }
      this.totalBreakdown.total = total;
      return total;
    }
    return false;
  }

  protected get priceEur(): number | false {
    const { vehicleDetails } = this.vehicleData;

    if (vehicleDetails?.priceGBP) {
      return this.currency.gbpEur(Number(vehicleDetails?.priceGBP));
    }
    return false;
  }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      if (p["url"]) {
        this.search(p["url"]);
      }
    });
    this.hero.setBg("/assets/img/race-car.jpg", "center 6%", undefined, "80%");
  }

  async search(vrm: string): Promise<boolean> {
    try {
      this.searching = true;
      this.motivationalText = "Looking for the vehicle.";
      this.reset();

      const searchResponse = await this.apiService.search(vrm);
      this.motivationalText = "Found the vehicle. Looking for more data.";

      let res = searchResponse?.data as VehicleDetails;
      if (res.loc != "uk") {
        throw new Error("We currently only support VRT checks on UK registered vehicles");
      }
      const detailsResponse = await this.apiService.getDetails("uk", res?.id);

      this.vehicleData.vehicleDetails = detailsResponse.data as VehicleDetails;
      // Scroll to report
      this.scroller.scrollToAnchor("ccqStart");
      // Swap reg number
      // TODO inform of reg number changes
      if (this.vehicleData.vehicleDetails.registrationNumber) {
        this.vrmSearch?.setVrm(this.vehicleData.vehicleDetails.registrationNumber);
      } else if (this.vehicleData.vehicleDetails.registrationNumber_ie) {
        this.vrmSearch?.setVrm(this.vehicleData.vehicleDetails.registrationNumber_ie);
      }
      if (this.vehicleData.vehicleDetails.priceGBP) {
        this.missingPriceForm.setValue({priceGBP: this.vehicleData.vehicleDetails.priceGBP});
      }
      await this.getVrt(res?.id);
      await this.getVat();
      await this.getCustoms();

      if (this.transportFormGroup.valid) {
        await this.getTransport();
      }

      this.searching = false;
      return true; // Return true to indicate the search was successful
    } catch (error: any) {
      console.error(error); // or any other error logging method
      this.vrmSearch?.setError(error.error?.msg ?? error.message);
      this.searching = false;
      return false; // Return false to indicate the search failed
    }
  }

  /**
   *  Get a new VRT calculation with the new values
   */
  submitVrt() {
    const { vehicleDetails, vatDetails, customsDetails } = this.vehicleData;
    this.getVrt(Number(vehicleDetails?.id), this.missingVarsForm?.value, 'missing');
    // Update the details
    if (vehicleDetails) {
      for (let key in this.missingVarsForm?.value) {
        (vehicleDetails[key as keyof VehicleDetails] as any) = this.missingVarsForm.get(key)?.value;
      }
      if (!customsDetails) {
        this.getCustoms();
      }
      if (!vatDetails) {
        this.getVat();
      }
    }
  }

  /**
   *  Get a new VAT / Customs calculation with the new price
   */
  submitPrice() {
    const { vehicleDetails } = this.vehicleData;

    if (!vehicleDetails) {
      return;
    }
    vehicleDetails.priceGBP = this.missingPriceForm?.get("priceGBP")?.value;
    this.getVat();
    this.getVrt(vehicleDetails.id,null,'missing');
    this.getCustoms();
  }

  /** Order by ascending property value **/
  valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return String(a.value).localeCompare(String(b.value));
  };
  private setOrder: {[key: string]: number } = {
    // General details
    "Make": 1,
    "Model": 2,
    "Description": 3,
    "Model version": 3,
    "Transmission": 4,
    "Engine type / fuel": 5,
    "Engine size": 6,
    "Body type": 7,
    "Number of doors": 8,
    "Colour": 9,
    "Mileage (miles)": 10,
    "Mileage (km)": 10,
    "CO2 emissions": 11,
    "NOx emissions": 12,
    "Year of Manufacture": 13,
    "Date of registration": 14,
    "License Plate": 15,
    "EU classification": 16,
    "MOT status": 17,
    "Tax status": 18,
    "Tax die": 19,
    "Last V5C issued": 20,
    // VRT specific values
    "ROS version designation": 3.5,
    "First registered in Japan?": 10.5,
    "WLTP CO2 emissions": 11.5,
    "VRT statistical code": 21,
    "CO2 charge rate": 22,
    "VRT original OMSP": 23,
    "VRT current OMSP": 24,
    "OMSP adjustment for month": 25,
    "OMSP Mileage reduction": 26,
    "Depreciation code": 27,
    "Depreciation rate": 27.5,
    "VRT CO2 charges": 28,
    "VRT NOx charge": 29,
    "VRT charge": 30
  }
  /** Order by set order **/
  ccqOrder = (a:any, b: any): number => {
    // console.log(`Order ${a.key}: ${this.setOrder[a.key]}`)
    const a_order = this.setOrder[a.key];
    const b_order = this.setOrder[b.key];
    return a_order - b_order;
  };

  getQuote() {
    const { vehicleDetails, vrtDetails, customsDetails, transportDetails } = this.vehicleData;
    const config = {
      data: {
        vehicleDetails: vehicleDetails,
        currencyRate: this.currency.rates["gbp"],
        total: this.total,
        customs: customsDetails,
        transport: transportDetails,
        vrt: vrtDetails,
        ...this.totalBreakdown,
      },
      maxWidth: 700,
      headerTitle: `${vehicleDetails?.make} ${vehicleDetails?.model} Import Quote`,
    };

    this.dialogService.openDialog(QuoteComponent, config);
  }

  protected async getTransport() {
    if (this.postCodeLoading) {
      return;
    }
    if (this.transportFormGroup.invalid) {
      this.transportError = "Please enter a valid post code for both origin and destination.";
      return;
    }
    this.motivationalText = "Attempting to calculate the transport costs.";

    this.postCodeLoading = true;
    await this.apiService
      .getTransport(this.transportFormGroup.value)
      .then(response => {
        if (response.success) {
          this.vehicleData.transportDetails = response?.data;
        }
      })
      .catch(e => (this.transportError = e.message));
    this.postCodeLoading = false;
    if (this.transportFormGroup.get("ie")?.valid) {
      // this.storage.set('UKpostCode', this.UKpostCodeControl.value);
      this.storage.set("IEpostCode", this.transportFormGroup.get("ie")?.value);
    }
  }

  protected reset() {
    this.vrmSearch?.resetError();
    this.manuallySelectedStatCode = null;
    this.vrtLoading = true;
    this.detailsLoading = true;
    this.vatLoading = false;
    this.postCodeLoading = false;
    this.totalsLoading = true;
    this.showVrtDetails = false;
    this.similar = new MatTableDataSource<StatCode[]>([]);
    this.vehicleData = {
      vrtDetails: undefined,
      vehicleDetails: undefined,
      vatDetails: undefined,
      customsDetails: undefined,
    };

    this.vehicleData.transportDetails = undefined;

    this.vrtError = undefined;
    this.vatError = undefined;
    this.transportError = undefined;

    this.missingVariables = [];
    this.missingOptions = {};
    this.missingVarsForm = new FormGroup([]);
    this.missingPriceForm.reset();
    this.vinRevealService.showVin = false;
  }

  protected async changeVrtMissingForm(ev: MatSelectChange) {
    const { vehicleDetails } = this.vehicleData;

    // Update the search result
    // console.log(ev.source.selected);
    if (vehicleDetails) {
      const key = ev.source.ngControl.name as keyof VehicleDetails;

      if (key) {
        (vehicleDetails[key] as any) = ev.value;
      }
      // This limits the choice of statcodes after
      if (key === "statCode" && !vehicleDetails.model) {
        // We can also get the model
        const option = <MatOption>ev.source.selected;
        let label = option._text?.nativeElement.textContent;
        if (label) {
          label = label.split(" - ")[0];
          vehicleDetails.model = label;
        }
      }

      // Update the options accordingly
      try {
        const r = await this.apiService.getOptions(vehicleDetails);
        this.missingOptions = r.data;
        // Select single vals
        if (this.missingVariables) {
          for (let v of this.missingVariables) {
            if (this.missingOptions[v] && Object.keys(this.missingOptions[v]).length === 1) {
              // There's only one option
              const val: any = Object.keys(this.missingOptions[v])[0];
              // @ts-ignore
              this.missingVarsForm.get(v)?.setValue(val, { emitEvent: false });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  private validateIrishPostCode(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (
        control.value?.toUpperCase().search(/(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/) === -1
      ) {
        return { invalidPostCode: "Please enter a valid Irish postcode" };
      }
      return null;
    };
  }

  private validateUKPostCode(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (
        control.value?.search(
          /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
        ) === -1
      ) {
        // console.error("Invalid ", control.value);
        return { invalidPostCode: "Please enter a valid UK postcode" };
      }
      // console.log("Valid ", control.value);
      return null;
    };
  }

  private getpostCode(): string | undefined {
    return this.storage.get("IEpostCode");
  }

  /**
   * Request a VRT calculation from the API
   * Valid types are:
     * 'auto' => the default, which attempts to match the vehicle automatically using all available sources
     * 'missing' => used to provide missing data points like mileage, price etc. Other fields can still be autmatically sourced
     * 'manual' => a manual VRT calculation using only the provided data points
     * 'statCode' => User has selected a vehicle from the table of close matches
   * @param vehicle_id
   * @param data
   * @param type
   */
  public async getVrt(vehicle_id: number, data: {} | null = null, type: string | null = null) {

    this.motivationalText = "Attempting to calculate the VRT.";
    this.vrtLoading = true;
    this.vrtError = undefined;
    this.missingOptions = {};
    if (this.manuallySelectedStatCode) {
      type = 'statCode';
      let d: any = data ?? {};
      d['statCode'] = this.manuallySelectedStatCode;
      data = d;
    }
    const response = await this.apiService.getVrt(vehicle_id, data, type)
      .catch(e => {
        console.error(e);
        this.vrtError = e.error.msg ?? e.message;
        return;
      });

    if (response?.success) {
      this.vrtError = undefined;
      this.vehicleData.vrtDetails = response.data;
    } else {
      this.vrtError = response?.msg;
      const res = response?.data;
      if (res?.missingVariables) {
        this.missingVariables = Object.values(res.missingVariables);

        if (this.missingVariables) {
          for (const v in res.missingVariables) {
            this.missingVarsForm?.addControl(res.missingVariables[v], new FormControl(), {
              emitEvent: false,
            });
          }
        }
      } else if (res?.similar?.length) {
        // We got similar models
        this.similar = new MatTableDataSource(res.similar);
      }
      this.missingOptions = res?.options;
    }
    this.vrtLoading = false;
    await this.feedback.trigger(this.vehicleData.vehicleDetails.registrationNumber ?? this.vehicleData.vehicleDetails.registrationNumber_ie, 'ccq');

  }

  private async getVat() {
    this.motivationalText = "Attempting to calculate the VAT.";
    const { vehicleDetails } = this.vehicleData;
    if (!vehicleDetails?.priceGBP) {
      this.vatLoading = false;
      this.vatError =
        "We do not have the price on record for this vehicle. Please provide the value.";
      return;
    }
    this.vatError = undefined;
    this.vatLoading = true;
    await this.apiService
      .getVat(vehicleDetails.priceGBP)
      .then(response => {
        if (response.success) {
          this.vatError = undefined;
          this.vehicleData.vatDetails = response?.data;
          return;
        }
        this.vatError = response.msg;
      })
      .catch(error => (this.vatError = error.message));
    this.vatLoading = false;
    this.totalsLoading = false;
  }

  private async getCustoms() {
    this.motivationalText = "Attempting to calculate customs duty.";
    const { vehicleDetails } = this.vehicleData;


    if (!vehicleDetails?.priceGBP) {
      this.customsLoading = false;
      this.customsError =
        "We do not have the price on record for this vehicle. Please provide the value.";
      return;
    }
    this.customsLoading = true;
    this.customsError = undefined;
    await this.apiService
      .getCustoms(vehicleDetails)
      .then(response => {
        if (response.success) {
          this.vehicleData.customsDetails = response.data;
          this.customsError = undefined;
        } else {
          this.customsError = response.msg;
        }
      })
      .catch(error => (this.customsError = error.message));

    this.customsLoading = false;
    this.totalsLoading = false;
  }

  // private populateOrigin() {
  //   const { vehicleDetails } = this.vehicleData;
  //
  //   if (vehicleDetails?.["place_id"]) {
  //     this.UKpostCodeControl.setValue(this.searchResult?.["place_id"]);
  //     this.origin = String(this.searchResult?.["place_name"]);
  //   }
  // }


  showVrtDetails: boolean = false;
  displayedColumns = ["statCode","version","model","transmissionType","numberOfDoors","engineType","bodyType","engineCapacity","euClassification","wltpco2"];
  public get evExempt(): boolean {
    return this.vehicleData.vrtDetails?.vrtEuro == 0 && this.vehicleData.vehicleDetails.engineType == 3;
  }
  public get anyFootnote(): boolean {
    return  (
      this.evExempt ||
      this.vehicleData?.vrtDetails?.source == "tcs-estimated-omsp" ||
      this.vehicleData?.vrtDetails?.source == "tcs-prestige"
    );
  }

  /**
   * Select a statcode from the near-match list
   *
   * @param statCode
   */
  selectStatCode(statCode: number) {
    this.manuallySelectedStatCode = statCode;
    return this.getVrt(this.vehicleData.vehicleDetails["id"],this.vehicleData.vehicleDetails);
  }

  editInputs() {
    this.dialog.open(ManualVrtComponent,{
      data: this
    });
  }
}
