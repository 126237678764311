import {EventEmitter, Inject, Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {UserService} from "./user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SortDirection} from "@angular/material/sort";

export interface SortCriteria {
  active: string | null;
  direction: SortDirection;
}

export interface VRT {
  bca_id: number;
  registrationNumber: string;
  id: number,
  make: string,
  model: string,
  transmissionType: number,
  numberOfDoors: number,
  engineType: number,
  bodyType: number,
  engineCapacity: number,
  statCode: number,
  mileage: number,
  yearOfRegistration: number,
  euroStatus: number,
  versionData: string,
  vrtEuro: number,
  omsp_current: number,
  attemptedVrt_ts: string, // 2024-02-19 00:24:32
  astonbarclay_id: number | null,
  manheim_id: number | null,
  autotrader_id: number | null,
  priceGBP: number,
  priceEst: number,
  priceEurExVat: number,
  priceEstExVat: number,
  shippingEur: number,
  customsDuty: number,
  profit: number,
  exchange_rate: number,
  roi: number // -0.17
}

@Injectable({
  providedIn: "root",
})

export class FcfService {
  public total: number = 0;
  public makes: { [make: string]: string[] } = {};
  public bodyTypes: number[] = [];
  public loadingFacets: boolean = false;
  public loading: boolean = false;
  public colours: string[] = [];
// MaxMins
  public maxMins = {
    maxPrice: 200000,
    maxCost: 200000,
    maxEngineSize: 5000,
    maxKms: 400000,
    maxProfit: 70000,
    minProfit: 200,
    maxRoi: 200,
    maxYear: new Date().getFullYear(),
    minYear: 2000,
    maxCo2: 400,
  };
  public updatedMaxMins = new EventEmitter();

  constructor(private apiService: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  /**
   * Get source (name and URL to ad)
   * @param element
   */
  static getSource(element: VRT | any): { url: string, source: string } {
    let source = {
      url: "", source: ""
    };
    if (element.autotrader_id) {
      source.url = `https://www.autotrader.co.uk/car-details/${element.autotrader_id}`;
      source.source = "Autotrader.co.uk";
    } else if (element.astonbarclay_id) {
      source.url = `https://catalogue.astonbarclay.net/details/catalogue/${element.astonbarclay_id}`;
      source.source = "AstonBarclay.net";
    } else if (element.manheim_id) {
      source.url = `https://www.manheim.co.uk/search?keywords=${element.registrationNumber}`;
      source.source = "Manheim.co.uk";
    } else if (element.bca_id) {
      source.url = `https://www.bca.co.uk/lot/${element.registrationNumber}`;
      source.source = "BCA.co.uk";
    } else if (element.source == "carsireland") {
      source.url = `https://www.carsireland.ie/${element.id}`;
      source.source = "Cars Ireland"
    } else if (element.source == "carzone") {
      source.source = "CarZone"
      source.url = `https://www.carzone.ie/used-cars/${element.make}/${element.model}/fpa/${element.id}`;
    } else if (element.source == "donedeal") {
      source.source = "DoneDeal"
      source.url = `https://www.donedeal.ie/cars-for-sale/a/${element.id}`;
    }
    return source;
  }

  public async get(pageSize: number, pageIndex: number, sortCriteria: SortCriteria, filters: {
    bodyTypes: number[],
    makes: string[],
    models: string[],
    kmsMin: number,
    kmsMax: number,
    co2Min: number,
    co2Max: number,
    engineSizeMin: number,
    engineSizeMax: number,
    engineTypes: number[],
    purchasePriceMin: number,
    purchasePriceMax: number,
    totalCostMin: number,
    totalCostMax: number,
    profitMin: number,
    profitMax: number,
    roiMin: number,
    roiMax: number,
    sellerTypes: string[],
    yearMin: number,
    yearMax: number,
    transmissionTypes: number[],
    numberOfDoors: number[],
    keyword: string,
    colours: string[],
  }): Promise<VRT[]> {
    const res = await this.apiService.getFcf(pageSize, pageIndex, sortCriteria.direction, sortCriteria.active, filters);
    if (res?.success) {
      this.total = res.data.rows;
      return res.data.fcf;
    }
    throw new Error(res.msg ?? 'Could not retrieve FCF data');
  }

  initForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      makes: new FormControl([]),
      models: new FormControl({value: [], disabled: true}),
      kmsMin: new FormControl(0),
      kmsMax: new FormControl(this.maxMins.maxKms),
      engineSizeMin: new FormControl(0),
      engineSizeMax: new FormControl(this.maxMins.maxEngineSize),
      engineTypes: new FormControl([]),
      purchasePriceMin: new FormControl(0),
      purchasePriceMax: new FormControl(this.maxMins.maxPrice),
      totalCostMin: new FormControl(0),
      totalCostMax: new FormControl(this.maxMins.maxCost),
      profitMin: new FormControl(this.maxMins.minProfit),
      profitMax: new FormControl(this.maxMins.maxProfit),
      roiMin: new FormControl(0),
      roiMax: new FormControl(this.maxMins.maxRoi),
      sellerTypes: new FormControl([]),
      yearMin: new FormControl(this.maxMins.minYear),
      yearMax: new FormControl(this.maxMins.maxYear),
      co2Min: new FormControl(0),
      co2Max: new FormControl(this.maxMins.maxCo2),
      transmissionTypes: new FormControl([]),
      numberOfDoors: new FormControl([]),
      bodyTypes: new FormControl([]),
      keyword: new FormControl(""),
      colours: new FormControl([]),
    });
  }

  async getFacets() {
    if (!this.loadFacets()) {
      const res = await this.apiService.getFacets();
      if (res?.success) {
        this.makes = res.data.makes;
        this.bodyTypes = res.data.bodyTypes;
        this.colours = res.data.colours;
        this.maxMins = res.data.maxMins;
        this.saveFacets();
      }
    }
  }

  private saveFacets(): void {
    this.storage.set("makes", this.makes);
    this.storage.set("bodyTypes", this.bodyTypes);
    this.storage.set("colours", this.colours);
    this.storage.set("maxMins", this.maxMins);
  }

  private loadFacets(): boolean {
    this.makes = this.storage.get("makes");
    this.bodyTypes = this.storage.get("bodyTypes");
    this.colours = this.storage.get("colours");
    const maxMins = this.storage.get("maxMins");
    if (maxMins) {
      this.maxMins = maxMins;
    }
    return Boolean(this.makes && this.bodyTypes && this.colours && maxMins);
  }

}
