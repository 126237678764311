import {Component, Input, OnInit} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {ApiService} from "../../../../services/api.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatOption,
    MatRow,
    MatRowDef,
    MatSelect,
    MatTable,
    MatHeaderCellDef,
    MatProgressSpinner,
    NgIf
  ],
  templateUrl: './admin-users.component.html',
  styleUrl: './admin-users.component.scss'
})
export class AdminUsersComponent implements OnInit {

  protected users = new MatTableDataSource([]);
  protected loading: boolean | undefined;
  displayedColumns_users = ['lastLoginAt','email','role'];
  constructor(private apiService: ApiService) {
  }
  async ngOnInit() {
    this.loading = true;
    await this.loadUsers();

  }

  private async loadUsers() {
    this.users = new MatTableDataSource(await this.apiService.getUsers());
    this.loading = false;
  }
  getRole(element: any): string {
    if (element.customClaims['admin'] === true) {
      return 'admin';
    } else if (element.customClaims['editor'] === true) {
      return 'editor';
    }
    return '';
  }

  setRole($role: string, element: any) {
    this.apiService.assignRole($role, element.uid);
  }
}
