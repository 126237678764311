import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { MatError, MatFormField, MatInput } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import {MatDialog} from "@angular/material/dialog";
import {AccessDialogComponent} from "../dialogs/access-dialog/access-dialog.component";

@Component({
  selector: "app-vrm-search",
  standalone: true,
  imports: [
    MatInput,
    ReactiveFormsModule,
    MatFormField,
    NgOptimizedImage,
    MatProgressSpinner,
    MatError,
    NgIf,
    NgClass,
  ],
  templateUrl: "./vrm-search.component.html",
  styleUrl: "./vrm-search.component.scss",
})
export class VrmSearchComponent implements AfterViewInit {
  @ViewChild("vrmInput") vrmInput: ElementRef | undefined;
  @Input() mode: "cyc" | "ccq" | undefined;
  @Input() flag: "ie" | "uk" | undefined;
  @Input() disabled: boolean = false;

  inputValue: string = "";

  protected error: string | null = null;

  // xxxx xxx UK
  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  public prodName(shortHand: string | undefined): string {
    switch (shortHand) {
      case "cyc":
        return "Check Your Car";
      case "ccq":
        return "Calculate Cars Quicker";
      default:
        return shortHand ?? "";
    }
  }

  ngAfterViewInit(): void {
    this.route.params.subscribe(p => {
      if (p["url"]) {
        this.setVrm(p["url"]);
      }
    });
  }
  // YYY–CC–SSSSSS Ireland

  public setVrm(vrm: string) {
    if (this.vrmInput) {
      this.vrmInput.nativeElement.value = vrm;
    }
  }

  public setError(error: string) {
    this.error = error;
  }

  public resetError() {
    this.error = null;
  }

  public go($event: SubmitEvent) {
    // @ts-ignore
    const input = <HTMLInputElement>$event.target[0];
    $event.preventDefault();
    if (this.userService.isLoggedIn()) {
      return this.router.navigate(['home',this.mode,{url: input.value}]).catch(e => console.error(e));
    }
    // User is not logged in
    return this.dialog.open(AccessDialogComponent,{
      data: {
        feature: this.prodName(this.mode)
      }
    });

  }
}
