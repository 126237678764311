<section class="header">
  <h1>My account</h1>
</section>
<section class="pageContent">
  <div class="accountDetails">
    <h3>Account details</h3>
    <div class="tripleCol">
      <div class="header">Your Name</div>
      <div>{{ userService.name }}</div>
      <button mat-raised-button (click)="editDetails()">Edit</button>

      <div class="header">Business Name</div>
      <div>{{ business_details?.business_name }}</div>
      <button mat-raised-button (click)="editDetails()">Edit</button>

      <div class="header">Phone Number</div>
      <div>{{ business_details?.business_phone }}</div>
      <button mat-raised-button (click)="editDetails()">Edit</button>

      <div class="header">Email</div>
      <div>{{ userService.email }}</div>
      <button mat-raised-button (click)="editEmail()">Edit</button>

      <div class="header">Password</div>
      <div>*****</div>
      <button mat-raised-button (click)="editPwd()">Edit</button>

      <div class="header">Business Address</div>
      <div>{{ business_details?.business_address }}</div>
      <button mat-raised-button (click)="editDetails()">Edit</button>

      <div class="header">VAT Number</div>
      <div>{{ business_details?.business_vat }}</div>
      <button mat-raised-button (click)="editDetails()">Edit</button>

      <div class="header">Notifications</div>
      <div></div>
      <button mat-raised-button routerLink="/home/notifications">Edit</button>

    </div>
  </div>
  <div class="paymentsBilling">
    <h3>Payments and Billing</h3>
    @if (plan.nextCharge) {
    <div class="doubleCol">
      <div class="header">Subscription plan</div>
      <div> {{ plan.description }}</div>

      <div class="header">Next billing date</div>
      <div> {{ plan.nextCharge | date }}</div>

      <div class="header">Credit quota</div>
      <div> {{ userService.quota }} / month</div>

      @if (userService.quota_extra) {
        <div class="header">Additional credits remaining</div>
        <div> {{ userService.quota_extra }}</div>
      }
    </div>
    }
    <ul class="singleCol">
        <button mat-raised-button *ngIf="plan.nextCharge" (click)="showPaymentUpdate()">Update Payment Method</button>
        <button mat-raised-button routerLink="/choose-product" role="link">See Available Plans</button>
        <button mat-raised-button *ngIf="plan.nextCharge" routerLink="/home/buy-credits" role="link">Purchase additional credits</button>
        <button mat-raised-button (click)="showInvoices()">Invoices</button>
        <button mat-raised-button (click)="dialog.open(closeAccountDialog)">Close your account</button>
    </ul>
  </div>
</section>

<ng-template #closeAccountDialog>
  <div>
    <h4 mat-dialog-title>Close account</h4>
    <mat-dialog-content>
      <p>Please email <a href="mailto:cancellations@thecarsite.com">{{ 'cancellations@thecarsite.com' }}</a></p>
    </mat-dialog-content>
  </div>
</ng-template>
