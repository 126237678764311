<div class="table searchResTable" role="table">
  <div class="table-segment">
    <div class="row">
      <div class="property">Make</div>
      <div class="value">{{ data.make }}</div>
    </div>
    <div class="row">
      <div class="property">Model</div>
      <div class="value">{{ data.model }}</div>
    </div>
    <div class="row">
      <div class="property">Version</div>
      <div class="value">{{ data.model_tfl ?? data.versionData }}</div>
    </div>
    <div class="row">
      <div class="property">Transmission</div>
      <div class="value">{{ data.transmissionType | transmissionType }}</div>
    </div>
    <div class="row">
      <div class="property">Engine Type</div>
      <div class="value">{{ data.engineType | engineType }}</div>
    </div>
    <div class="row">
      <div class="property">Engine Size</div>
      <div class="value" *ngIf="data.engineCapacity !== null">{{ data.engineCapacity | number }} cc</div>
    </div>
    <div class="row">
      <div class="property">Body Type</div>
      <div class="value">{{ data.bodyType | bodyType }}</div>
    </div>
    <div class="row">
      <div class="property">Number of Doors</div>
      <div class="value">{{ data.numberOfDoors }}</div>
    </div>
    <div class="row">
      <div class="property">Colour</div>
      <div class="value">{{ data.colour }}</div>
    </div>
    <div class="row">
      <div class="property">Mileage</div>
      @if (data.mileage){
        @if (data.registrationNumber_ie) {
          <div class="value">{{  data.mileage | milesToKm | number }} kms</div>
        } @else {
          <div class="value">{{ data.mileage | number }} miles</div>
        }
      } @else {
        <div class="value">Not Registered</div>
      }
    </div>
    <div class="row">
      <div class="property">First Registered in Japan?</div>
      <div class="value">{{ data.firstRegisteredInJapan ? 'Yes' : 'No' }}</div>
    </div>

    <div class="row">
      <div class="property">CO2 Emissions</div>
      <div class="value" *ngIf="data.co2Emissions !== null">{{ data.co2Emissions }} g/km</div>
    </div>
    <div class="row">
      <div class="property">NOx Emissions</div>
      <div class="value" *ngIf="data.nox !== null">{{ data.nox }} g/km</div>
    </div>
  </div>
  <div class="table-segment">

    <div class="row">
      <div class="property">Year of Manufacture</div>
      <div class="value">{{ data.yearOfManufacture }}</div>
    </div>
    <div class="row">
      <div class="property">Date of Registration</div>
      @if (data.monthOfFirstRegistration) {
        <div class="value">{{ data.monthOfFirstRegistration | date }}</div>
      } @else {
        <div class="value">{{ data.monthOfRegistration }}-{{ data.yearOfRegistration }}</div>
      }
    </div>
    <div class="row">
      <div class="property">License Plate</div>
      <div class="value">{{ data.registrationNumber ?? data.registrationNumber_ie }}</div>
    </div>
     <div class="row">
      <div class="property">VIN</div>
      <div class="value">
        @if (data.vin) {
          @if(vinRevealService.showVin) {
          {{ data.vin }}
          } @else {
            <button (click)="vinRevealService.showVinDialog()">Show VIN</button>
          }
        }
      </div>
    </div>
    <div class="row">
      <div class="property">EU Classification</div>
      <div class="value">{{ data.euClassification }}</div>
    </div>
    @if (data.registrationNumber_ie) {
      <div class="row">
        <div class="property">NCT Expiry</div>
        <div class="value">{{ data.nctExpiry }}</div>
      </div>
    } @else {
      <div class="row">
        <div class="property">MOT Status</div>
        <div class="value">{{ data.motStatus }}</div>
      </div>
    }
    <div class="row">
      <div class="property">Tax Status</div>
      <div class="value">{{ data.taxStatus }}
        @if (data.vehicleStatus && data.vehicleStatus != 'CURRENT') { ({{data.vehicleStatus}}) }
      </div>
    </div>
    <div class="row">
      <div class="property">Tax Due</div>
      <div class="value">{{ data.taxDueDate }}</div>
    </div>
    <div class="row">
      <div class="property">Last change of ownership</div>
      <div class="value">{{ data.dateOfLastV5CIssued | date }}</div>
    </div>
    <div class="row">
      <div class="property">Seating Capacity</div>
      <div class="value">{{ data.seats }}</div>
    </div>
    <div class="row">
      <div class="property">Wheel Plan</div>
      <div class="value">{{ data.wheelplan }}</div>
    </div>
    <div class="row">
      <div class="property">Weight (unladen)</div>
      <div class="value" *ngIf="data.revenueWeight">{{ data.revenueWeight | number }} kg</div>
    </div>
    <div class="row">
      <div class="property">Country of Origin</div>
      <div class="value">{{ data.countryOfOrigin }}</div>
    </div>
    <div class="row">
      <div class="property">Country of Manufacturer</div>
      <div class="value">{{ data.countryOfOrigin_vin }}</div>
    </div>
  </div>
</div>
