<div class="prodCard">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
  <table mat-table [dataSource]="users">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="lastLoginAt">
      <th mat-header-cell *matHeaderCellDef>Last login</th>
      <td mat-cell *matCellDef="let element">{{ element.metadata.lastLoginAt?.date ?? 'Never' }}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Privilege</th>
      <td mat-cell *matCellDef="let element">
        <mat-select [value]="getRole(element)" (valueChange)="setRole($event, element)">
          <mat-option value="">None</mat-option>
          <mat-option value="editor">Editor</mat-option>
          <mat-option value="admin">Admin</mat-option>
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns_users"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns_users;"></tr>
  </table>
</div>
