import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectChange} from "@angular/material/select";
import {CurrencyPipe, formatDate, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {ApiService} from "../../../services/api.service";
import {MatInput} from "@angular/material/input";
import {CcqHomeComponent} from "../../../pages/home/ccq/ccq-home.component";
import {Subscription} from "rxjs";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTooltip} from "@angular/material/tooltip";

interface RosVersion {
  statCode: number;
  version: string;
  transmissionType: number;
  numberOfDoors: number;
  engineType: number;
  bodyType: number;
  engineCapacity: number;
  wltpco2: number;
  nedc: number;
  omsp: number;
  deprCode: string;
  nox: number;
}
const bodyTypes: { value: number, label: string }[] = [
  { value: 41, label: "Convertible" },
  { value: 58, label: "Drop-side lorry" },
  { value: 77, label: "Estate" },
  { value: 40, label: "Hatchback" },
  { value: 92, label: "MPV" },
  { value: 37, label: "Other" },
  { value: 56, label: "Other van" },
  { value: 48, label: "Pick up" },
  { value: 1, label: "Saloon" },
  { value: 42, label: "Sports coupe (non convertible)" },
  { value: 6, label: "Van" },
  { value: 50, label: "Van with side windows" }
];
@Component({
  selector: 'app-manual-vrt',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    NgForOf,
    KeyValuePipe,
    MatLabel,
    MatInput,
    MatProgressSpinner,
    NgIf,
    MatTooltip,
    CurrencyPipe,
    NgClass
  ],
  templateUrl: './manual-vrt.component.html',
  styleUrl: './manual-vrt.component.scss'
})

export class ManualVrtComponent implements OnInit {
  protected loading = true;
  protected vrtForm: FormGroup;
  protected makesAndModels: { [make: string]: string[] } | undefined;
  protected versions: RosVersion[] | undefined;
  private changeSubscriptions: Subscription[] = [];
  months: { index: number, name: string }[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: CcqHomeComponent, private dialog: MatDialog, private apiService: ApiService) {
    // Create array of months with names
    for (let i = 0; i < 12; i++) {
      const date = new Date(0, i);
      const monthName = formatDate(date, 'MMMM', 'en');
      if (monthName) {
        this.months.push({ index: i + 1, name: monthName });
      }
    }
    if (data.vehicleData.vrtDetails?.omsp_current) {
      data.vehicleData.vrtDetails.omsp_current = Math.round(data.vehicleData.vrtDetails.omsp_current);
    }
    console.log('vehicleDetails', data.vehicleData.vehicleDetails);
    console.log('vrtDetails', data.vehicleData.vrtDetails);
    this.vrtForm = new FormGroup({
      make: new FormControl(data.vehicleData.vrtDetails?.make ?? (data.vehicleData.vehicleDetails.make ? data.vehicleData.vehicleDetails.make?.toUpperCase() : undefined),[Validators.required]),
      model: new FormControl(data.vehicleData.vrtDetails?.model ?? (data.vehicleData.vehicleDetails.model ? data.vehicleData.vehicleDetails?.model.toUpperCase() : undefined),[Validators.required]),
      statCode: new FormControl(undefined),
      transmissionType: new FormControl(data.vehicleData.vrtDetails?.transmissionType ?? data.vehicleData.vehicleDetails?.transmissionType,[Validators.required]),
      engineType: new FormControl(data.vehicleData.vrtDetails?.engineType ?? data.vehicleData.vehicleDetails?.engineType),
      engineCapacity: new FormControl(data.vehicleData.vrtDetails?.engineCapacity ?? data.vehicleData.vehicleDetails?.engineCapacity),
      bodyType: new FormControl(data.vehicleData.vrtDetails?.bodyType ?? data.vehicleData.vehicleDetails?.bodyType,[Validators.required]),
      numberOfDoors: new FormControl(data.vehicleData.vrtDetails?.numberOfDoors ?? data.vehicleData.vehicleDetails?.numberOfDoors,[Validators.required]),
      mileage: new FormControl(data.vehicleData.vrtDetails?.mileage ?? data.vehicleData.vehicleDetails?.mileage, [Validators.required]),
      co2Emissions: new FormControl(data.vehicleData.vrtDetails?.wltpco2 ?? data.vehicleData.vehicleDetails?.co2Emissions),
      nox: new FormControl(data.vehicleData.vrtDetails?.nox ?? data.vehicleData.vehicleDetails?.nox),
      omsp_current: new FormControl(data.vehicleData.vrtDetails?.omsp_current),
      yearOfRegistration: new FormControl(data.vehicleData.vehicleDetails?.yearOfRegistration ?? data.vehicleData.vrtDetails?.yearOfRegistration),
      monthOfRegistration: new FormControl(data.vehicleData.vehicleDetails?.monthOfRegistration ?? data.vehicleData.vrtDetails?.monthOfRegistration),
    });
    this.vrtForm.disable();
  }
  async ngOnInit() {
    // Fetch makes and model
    const res = await this.apiService.getMakesAndModels()
      .catch(e => console.error(e));
    if (res?.success) {
      this.makesAndModels = res.data;
    }
    // Get versions, if make and model defined
    if (this.vrtForm.get('make')?.value && this.vrtForm.get('model')?.value) {
      await this.getVersions(<MatSelectChange>{value: this.vrtForm.get('model')?.value});
      // Select the correct version
      if (this.data.vehicleData.vrtDetails?.statCode) {
        this.vrtForm.get('statCode')?.setValue(this.data.vehicleData.vrtDetails.statCode);
      }
    }
    this.vrtForm.enable();
    this.setSubscriptions();
    this.loading = false;
  }
  origOrder = (): number => {
    return 0;
  }
  async recalculate(){
    if (!this.vrtForm.valid) {
      return;
    }
    const data = this.vrtForm.value;
    this.vrtForm.disable();
    this.loading = true;
    this.data.vehicleData.vrtDetails = data;
    await this.data.getVrt(this.data.vehicleData.vehicleDetails.id, data, 'manual');
    this.data.scroller.scrollToAnchor("vrtStart");
    this.dialog.closeAll()
  }

  cancel() {
    this.dialog.closeAll();
  }

  protected readonly Object = Object;

  async getVersions($event: MatSelectChange) {
    this.versions = undefined;
    const model = $event.value;
    const make = this.vrtForm.get('make')?.value;
    const res = await this.apiService.getVersions(make, model)
      .catch(e => console.error(e));
    if (res?.success) {
      this.versions = res.data;
    }
  }

  updateValues($event: MatSelectChange) {
    const version = this.getVersion($event.value);
    // console.log('version', version);
    if (version) {
      this.resetSubscriptions();
      if (version.transmissionType) {
        this.vrtForm.get('transmissionType')?.setValue(version.transmissionType);
      }
      if (version.engineType) {
        this.vrtForm.get('engineType')?.setValue(version.engineType);
      }
      if (version.engineCapacity !== null) {
        this.vrtForm.get('engineCapacity')?.setValue(version.engineCapacity);
      }
      if (version.bodyType) {
        this.vrtForm.get('bodyType')?.setValue(version.bodyType);
      }
      if (version.numberOfDoors) {
        this.vrtForm.get('numberOfDoors')?.setValue(version.numberOfDoors);
      }
      if (version.wltpco2 !== null) {
        this.vrtForm.get('co2Emissions')?.setValue(version.wltpco2);
      }
      if (version.nox !== null) {
        this.vrtForm.get('nox')?.setValue(version.nox);
      } else {
        this.vrtForm.get('nox')?.setValue(this.data.vehicleData.vrtDetails?.nox ?? this.data.vehicleData.vehicleDetails?.nox);
      }
      this.setSubscriptions();
    }
  }
  private setSubscriptions() {

    // Reset statCode if we modify any params, except statCode, mileage, nox and year and month Of Reg
    for (let control in this.vrtForm.controls) {
      if (['statCode','mileage','nox','yearOfRegistration','monthOfRegistration','omsp_current'].indexOf(control) > -1) {
        continue;
      }
      const sub = this.vrtForm.get(control)?.valueChanges.subscribe(value => {
        // console.log(`Changed: ${value}`)
        this.vrtForm.get('statCode')?.setValue(undefined, {emitEvent: false });
        this.vrtForm.get('omsp_current')?.setValue(undefined, {emitEvent: false });
        this.mode = 2;
      });
      if (sub) {
        this.changeSubscriptions.push(sub);
      }
    }
    // Reset OMSP if we select a stat code
    let sub = this.vrtForm.get('statCode')?.valueChanges.subscribe(value => {
      if (!value) {
        return;
      }
      this.mode = 1;
      this.vrtForm.get('omsp_current')?.setValue(undefined, {emitEvent: false});
    });
    if (sub) {
      this.changeSubscriptions.push(sub);
    }
    // Reset StatCode if we set the OMSP
    sub = this.vrtForm.get('omsp_current')?.valueChanges.subscribe(value => {
      if (!value) {
        return;
      }
      this.mode = 3;
      this.vrtForm.get('statCode')?.setValue(undefined, {emitEvent: false});
    });
    if (sub) {
      this.changeSubscriptions.push(sub);
    }
  }
  protected readonly bodyTypes = bodyTypes;
  mode: number = 0; // 1 == statcode, 2 == Params, 3 == OMSP
  protected get statCode(): number | null {
    return this.vrtForm.value.statCode ?? null;
  }
  protected get version(): string | undefined {
    return this.versions?.find(v => v.statCode == this.statCode)?.version;
  }
  private resetSubscriptions() {
    for (const s of this.changeSubscriptions) {
      s.unsubscribe();
    }
    this.changeSubscriptions = [];
  }

  private getVersion(statCode: number): RosVersion | undefined {
    return this.versions?.find(v => v.statCode == statCode);
  }

  protected readonly String = String;
}
