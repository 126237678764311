import {Injectable} from '@angular/core';
import Dexie from "dexie";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends Dexie {

  constructor(private userService: UserService) {
    super('tcs');
    this.version(1).stores({
      log: "ts,vrm,endpoint,uuid,feedback"
    });
  }

  /**
   * Log the action
   * Check if we need to display a feedback dialog
   * @param vrm
   * @param endpoint
   */
  public async trigger(vrm: string, endpoint: string): Promise<void> {
    await this.logAction(vrm, endpoint);
    await this.toggleFeedback();
  }

  /**
   * Log the action
   * @param vrm
   * @param endpoint
   * @private
   */
  private async logAction(vrm: string, endpoint: string) {
    await this.table("log").add({
      ts: new Date(),
      vrm: vrm,
      endpoint: endpoint,
      uuid: this.userService.uuid
    });
  }

  /**
   * Check if we need to show a feedback dialog
   * We show it every 3 actions, but max 3 times in a day
   * @private
   */
  private async toggleFeedback() {
    // Get the logs for past 24h
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const logs = await this.table("log")
      .where('ts')
      .above(yesterday)
      .reverse()
      .toArray();
    console.log('logs', logs);

  }
}
